@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "Orbitron", sans-serif;
}
p {
  font-family: "Advent Pro", sans-serif;
}
.navbar {
  padding: 6px 0 0 0;
  border-top: 10px solid #00223f;
  // background: rgba(10, 41, 68, 0.4);
  // box-shadow: 0 3px 45px 0 rgba(23, 155, 206, 0.9);
  // border-top: 4px solid #ff8600;
  // backdrop-filter: blur(9.5px);
  // -webkit-backdrop-filter: blur(9.5px);
  .nav-link {
  text-decoration: none;
  color:#fff;
  font-weight: 600;
  font-size:12px;
  line-height: 22px;
  text-transform:uppercase;
  position: relative;
  letter-spacing: 1px;
  min-width: 0px;
  &:hover {
    color:#ffb900;
  }
}

}

.custom-navbar {
  width: 100%;
  .navbar-brand {
    width: 320px;
  }
}
.banner-owl-wrapper {
 position: relative;
}

.navbar-collapse {
  justify-content: center;
}
.navbar-nav {
  gap: 20px;
}

.navbar-nav .nav-link.active {
  color: #986d43;
  text-align: center;
}


@keyframes animateBackground {
  0% { background-size: 100% 100%; }
  70% { background-size: 110% auto; }
  100% { background-size:  100% auto; }
}
@keyframes fadeIn {
	0% { opacity: 0.6; }
	20% { opacity: 1; }
	60% { opacity: 1; }
	100% { opacity: 1; }
}

@keyframes zoomEffect {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2); /* Slight zoom in */
  }
}
.banner-wrapper {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
}

.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../image/banner/banner.png);
  background-size: cover;
  background-position: center;
  animation-name: fadeIn, zoomEffect;
	animation-duration: 10s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear;

  // background: url(../image/banner/banner.png);
  // background-position: top;
  // background-repeat: no-repeat;
  // background-size: cover;
  // min-height:100vh;
  // overflow: hidden;
  // position: relative;
  // width: 100%;
  // animation-name: fadeIn, zoomEffect;
	// animation-duration: 10s;
	// animation-iteration-count: infinite;
	// animation-direction: alternate;
	// animation-timing-function: linear;
}
/* The outermost element*/
.zoominheader {
  width:400px;
  height:250px;
  text-align:center;
  background-size: auto;
  background-attachment: fixed;
  background-repeat: repeat;
  position: relative;
  -webkit-animation: zoomin 10s ease-in infinite;
  animation: zoomin 10s ease-in infinite;
  transition: all .5s ease-in-out;
  overflow: hidden;
  position: fixed;
  top: 20px;
  right: 30%;
}

/* The innermost element*/
.zoomoutheader {
  width:400px;
  height:250px;
  text-align:center;
  background: none;
  -webkit-animation: zoomout 10s ease-in infinite;
  animation: zoomout 10s ease-in infinite;
  transition: all .5s ease-in-out;
  overflow: hidden;
}



/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
  0% {transform: scale(1);}
  50% {transform: scale(1.5);}
  100% {transform: scale(1);}
}
@keyframes zoomin {
  0% {transform: scale(1);}
  50% {transform: scale(1.5);}
  100% {transform: scale(1);}
} /*End of Zoom in Keyframes */

/* Zoom out Keyframes */
@-webkit-keyframes zoomout {
  0% {transform: scale(1);}
  50% {transform: scale(0.67);}
  100% {transform: scale(1);}
}
@keyframes zoomout {
    0% {transform: scale(1);}
  50% {transform: scale(0.67);}
  100% {transform: scale(1);}
}/*End of Zoom out Keyframes */



// Button Styles
.button-styles {
  margin: 30px auto 0;
  // background-color: rgba(10, 41, 68, 0.6);
  // border-radius: 8px;
  // border: 1px solid rgba(0,0,0,.15);
  position: fixed;
  z-index: 222;
  right: 0px;
}
@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}
@media only screen and (max-width: 479px) {

}

@media (min-width: 768px) and (max-width: 979px) {
  .ag-format-container {
    width: 750px;
  }

}

@media (min-width: 980px) and (max-width: 1161px) {
  .ag-format-container {
    width: 960px;
  }

}

// Banner Text Spark

.footer {
  background: #000;
  position: relative;
  z-index: 10;
  padding:24px;
  color: #fff;
  box-shadow: 1px 3px 10px 3px rgba(243, 155, 0, 0.9);
  // border-top:1px solid rgba(243, 155, 0, 0.9);
  a {
    color: #ffb900;
    text-decoration: none;
  }
}

.arrow-animate{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  animation: animateBg 50s linear infinite; 

span{
  position: absolute;
  top:50%;
  left:50%;
  width: 4px;
  height: 4px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(255,255,255,0.1),0 0 0 8px rgba(255,255,255,0.1),0 0 20px rgba(255,255,255,0.1);
  animation: animate 3s linear infinite;
}
span::before{
  content:'';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: 1px;
  background: linear-gradient(90deg,#00223f,transparent);
}
span:nth-child(1){
  top: 0;
  right: 0;
  left: initial;
  animation-delay: 0s;
  animation-duration: 1s;
}
span:nth-child(2){
  top: 0;
  right: 80px;
  left: initial;
  animation-delay: 0.2s;
  animation-duration: 3s;
}
span:nth-child(3){
  top: 80;
  right: 0px;
  left: initial;
  animation-delay: 0.4s;
  animation-duration: 2s;
}
}

@keyframes animateBg{
  0%,100%{
      transform: scale(1);
  }
  50%{
      transform: scale(1.2);
  }
}
@keyframes animate
{
  0%
  {
      transform: rotate(315deg) translateX(0);
      opacity: 1;
  }
  70%
  {
      opacity: 1;
  }
  100%
  {
      transform: rotate(315deg) translateX(-1000px);
      opacity: 0;
  }
}
// mobile-view
.mobile-list-view {
  li {
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;
    position: relative;
    .circle-item {
      width: 70px;
      height: 70px;
      min-width: 70px;
      min-height: 70px;
      margin-right: 16px;
      line-height: 58px;
      font-size: 14px;
      display: inline-block;
      vertical-align: middle;
      color: #00138d;
      font-weight: bold;
      font-size: 20px;
      border: 8px solid #c68c18;
      border-radius: 50%;
      text-align: center;
      overflow: hidden;
      cursor: pointer;
      box-shadow: none;
      background-color: #fff
    }
    .label-item {
      background: transparent;
      padding: 12px 15px;
      line-height:24px;
      backdrop-filter: blur(9.5px);
      -webkit-backdrop-filter: blur(9.5px);
      box-shadow: 0 3px 25px 0 rgba(243, 155, 0, 0.9);
      border-top: 4px solid #f39b00;
      border-radius: 30px 0 30px 0px;
      color: #fff;
      min-width: 100px;
    }
    &:after {
      content: "";
      border: 1px dashed #fff;
      height: 77%;
      position: absolute;
      left: 34px;
      top: 70px;
    }
    &:last-child:after {
      display: none;
    }
  }
}
.social {
  display: flex;
  a {
    background-color: #0a294499;
    border: 1px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    &:hover {
      background: #cf1717;
    }
  }
  img {
    width: 25px;
    max-width: 100%;
  }
}
@media(max-width: 767px){
     .custom-navbar {
      width: 100vw;
      .navbar-brand {
        width: 220px;
      }
    }
    .copy p {   
        font-size: 10px !important;
        letter-spacing: 2px !important;
        padding: 10px 10px;
        word-break: break-all;
    }
    .main{
      padding: 440px 0 0 !important;
    }
    h1.heading-txt {
      font-size: 40px;
    }    .blinking-glow {
      left: -143px;
      top: 126%;
    }
    .book-wrap {
      margin-top: -83px;
    }
    .title-wrapper:before {
      left: 72px;
    }
    .zoominheader, .zoomoutheader {
      width: 150px !important;
      height: 84px !important;
      top: 62px !important;
      animation: none !important;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 8px;
      border: 1px solid rgba(0,0,0,.15);
    }
    .banner {
      background-size:contain!important;
      background-repeat: no-repeat;
      animation: none !important;
      background-position: top;
      height: 500px !important;
    }
    .button-styles {
      margin: 10px auto 0 !important;;
      right: 10px;
    }
    body {
      overflow-x: hidden;
      background:#1c4a74;
  }
  .social {
    display: flex;
    a {
      width:35px;
      height: 35px;
      min-width: 35px;
      min-height: 35px;
      margin-right:8px;
    }
    img {
      width: 25px;
      max-width: 100%;
    }
  }
}

// spinner loader
.spinner-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:9999;
  background-color:#1c4a74;/* Adjust the alpha value for transparency */
}

.spinner-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:9999;
}

.spinner-wrap {
  position: relative;
  width: 150px; /* Adjust according to your loader circle size */
  height: 150px; /* Adjust according to your loader circle size */
  border-radius: 50%;
  border: 4px solid #ffffff; /* Adjust color and transparency */
  border-left-color: #cf1717;
  animation: spin 5s linear infinite;
  background-color: #ffffff;
}

.spinner-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:120px; /* Adjust according to your logo size */
  height: auto;
  z-index:9999;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fa-blink {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
.fa-blink {
animation: fa-blink 2s linear infinite;
}