@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@import "~bootstrap/scss/bootstrap";
@import 'bootstrap/dist/css/bootstrap.min.css';
*{
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}
body{
  overflow-x: hidden;
}
img{
  width: 100%;
}
.navbar-toggler {
  background-color: #2f2c2e;
  border: 3px solid #00223f;
  position: absolute;
  top: 12px;
  right: 20px;
  z-index: 999;
}
.navbar-toggler-icon {
  // background-image: url(assets/image/hamburger.svg) !important;
  background:#FFFFFF;
  -webkit-mask: url(assets/image/hamburger.svg) no-repeat center;
  mask: url(assets/image/hamburger.svg) no-repeat center !important;
}
@media(max-width:991px) {
  .navbar-collapse {
    position: fixed;
    width: 100%;
    top:70px;
    right:-100%;
    background: rgba(0,0,0,0.8);
    z-index: 999;
    height: 100vh;
    transition: 0.3s ease;
    &.show {
      right:0;
    }
    .nav-link {
      line-height: 40px !important;
      border-bottom: 1px solid #3f3f3f;
      padding:20px;
    } 

  }

}
